<template>
  <div class="pageMain">
    <div class="flex">
      <!-- 组织项目树 -->

      <OriginTree class="tree" :filterText="filterText" @checked="init">
        <template slot="search">
          <SearchLeft
            @search="search"
            @reset="reset"
            info="项目信息"
            :iconShow="false"
          >
            <el-form :inline="true" :model="searchForm">
              <el-form-item label="" class="formItem">
                <el-input
                  v-model="filterText"
                  size="small"
                  placeholder="项目"
                ></el-input>
              </el-form-item>
            </el-form>
          </SearchLeft>
        </template>
      </OriginTree>

      <!--右边列表  -->
      <div class="form">
        <SearchHead
          @search="search"
          @reset="reset"
          :isExport="true"
          @tableExport="tableExport"
        >
          <el-form :inline="true" :model="searchForm">
            <el-form-item label="人员姓名">
              <el-input
                v-model="searchForm.staffName"
                size="small"
                placeholder="人员姓名"
              ></el-input>
            </el-form-item>
            <el-form-item label="人员性别">
              <el-select
                v-model="searchForm.staffSex"
                size="small"
                placeholder="人员性别"
              >
                <el-option label="男" value="0"></el-option>
                <el-option label="女" value='1'></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="手机号码">
              <el-input
                v-model="searchForm.staffMobile"
                size="small"
                placeholder="手机号码"
              ></el-input>
            </el-form-item>
            <el-form-item label="证件号码">
              <el-input
                v-model="searchForm.idCardNo"
                size="small"
                placeholder="证件号码"
              ></el-input>
            </el-form-item>
          </el-form>
        </SearchHead>
        <CustomTables
          :data="tableData"
          :headerCellStyle="headerCellStyle"
          @selectionChange="handleSelectionChange"
          @handleCurrentChange="handleCurrentChange"
      @handleSizeChange="handleSizeChange"
        
          :total="total"
          title="人员信息"
        >
          <template slot="btns">
            <el-button
              type="success"
              size="small"
              @click="addFrom"
              icon="el-icon-plus"
              class="btn"
              >新建</el-button
            >
          </template>

          <el-table-column type="selection" align="center" width="55">
          </el-table-column>
          <el-table-column prop="staffName" label="姓名" align="center">
          </el-table-column>
          <el-table-column prop="staffAccount" label="登录账号" align="center">
          </el-table-column>
          <el-table-column prop="staffSex" label="性别" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.staffSex == 0">男</span>
              <span v-if="scope.row.staffSex == 1">女</span>
            </template>
          </el-table-column>
          <el-table-column prop="orgaName" label="所属机构" align="center" how-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="staffMobile" label="电话号码" align="center" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="idCardNo" label="证件号码" align="center" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="staffStatus" label="使用状态" align="center">
            <template slot-scope="scope">
          <el-tag v-if="scope.row.staffStatus == 0" type="success">正常</el-tag>
          <el-tag v-if="scope.row.staffStatus == 1" type="danger">异常</el-tag>
        </template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="100">
            <template slot-scope="scope">
              <span @click="detailItem(scope.row)" class="detail">
                <i class="el-icon-edit"></i>
              </span>
              <span @click="deleteItem(scope.row)" class="detail delete">
                <i class="el-icon-delete"></i>
              </span>
            </template>
          </el-table-column>
        </CustomTables>
      </div>
    </div>
    <custom-dialog
      width="40%"
        :visible.sync="showDialog"
        :title="dialogTitle"
        @close="colseDialog"
        @submit="editsubmit">
      <el-form :model="editForm" label-width="80px" class="edit-form" :rules="rules" ref="editStaffForm">
        <el-form-item label="用户照片" prop="staffPic">
          <el-upload
              class="avatar-uploader"
              :action="`${uploadUrl}File/upload`"
              :show-file-list="false"
              :on-change="uploadChange"
              :data={type:1}
              :on-success="uploadSuccess">
            <img
                v-if="editForm.staffPic"
                :src="editForm.staffPic"
                class="avatar"/>
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-row >
          <el-col :span="12">
            <el-form-item label="用户姓名" prop="staffName">
              <el-input
                  size="small"
                  v-model="editForm.staffName"
                  placeholder="请输入用户姓名">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="性别" prop="staffSex">
              <el-select
                  class="block-select"
                  size="small"
                  v-model="editForm.staffSex"
                  placeholder="请选择性别">
                <el-option
                    v-for="item in sexState"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="所属机构" prop="orgaIdArr">
              <el-cascader
                  size="small"
                  class="block-select"
                  v-model="editForm.orgaIdArr"
                  :options="copanyListInfo"
                  popper-class="custom-cascader"
                  filterable
                  :props="{
                    checkStrictly: true,
                    expandTrigger: 'hover',
                  }"
                  @change="cascaderEditChange">
              </el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="使用状态" prop="staffStatus">
              <el-select
                  class="block-select"
                  size="small"
                  v-model="editForm.staffStatus"
                  placeholder="请选择使用状态">
                <el-option
                    v-for="item in useStaateList"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="手机号码" prop="staffMobile">
              <el-input
                  size="small"
                  @change="cahngeMobile"
                  v-model="editForm.staffMobile"
                  placeholder="请输入手机号码">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="证件号码" prop="idCardNo">
              <el-input
                  size="small"
                  v-model="editForm.idCardNo"
                  placeholder="请输入证件号码">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="账号" prop="staffAccount">
              <el-input
                  size="small"
                  v-model="editForm.staffAccount"
                  placeholder="请输入账号">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </custom-dialog>
  </div>
</template>

<script>
import OriginTree from "../../../components/common/OriginTree.vue";
import SearchHead from "../../../components/common/SearchHead";
import CustomTables from "../../../components/common/CustomTables";
import SearchLeft from "../../../components/common/SearchLeft.vue";
import sysManage from "../../../api/modules/sysManage";
import CustomDialog from "../../../components/common/CustomDialog";
import {sexList,useStaate} from "../../../config/dataStatus";
import config from "../../../api/config";
import {organizationCascader, phoneReg} from "../../../utils/tool";
import organizationTree from "../../../api/modules/organizationTree";
export default {
  components: {
    OriginTree,
    SearchHead,
    CustomTables,
    SearchLeft,
    CustomDialog
  },
  name: "statistics",
  data() {
    let checkMobile = (rule, value, callback) => {
      if (!phoneReg(value)) {
        callback(new Error('请输入正确的手机号'));
      } else {
        callback()
      }
    };
    return {
      showDialog:false,
      editForm: {
        idCardNo: "",
        orgaId: "", //机构id，有企业选企业，有公司选公司
        projId: "",
        staffId: "",
        staffMobile: "",
        staffName: "",
        staffPic: "",
        staffSex: "",
        staffAccount: "",
        orgaIdArr:[]
      },
      dialogTitle:'',
      searchForm: {
        currPage: 1,
        pageSize: 10,
      },
      filterText: "",
      tableData: [],
      options: [
       
      ],
      headerCellStyle: {
        backgroundColor: "#f3f2f7",
        color: "#373628",
      },
      total: 0,
      show: true,
      useStaateList:useStaate,
      copanyListInfo: [],
      rules: {
        staffName: [
          {required: true, message: "请输入人员姓名", trigger: "blur"},
        ],
        idCardNo: [
          {required: true, message: "请输入证件号码", trigger: "blur"},
        ],
        staffAccount: [
          {required: true, message: "请输入账号", trigger: "blur"},
        ],
        staffSex: [
          {required: true, message: "请选择性别", trigger: "change"},
        ],
        staffStatus: [
          {required: true, message: "请选择使用状态", trigger: "change"},
        ],
        orgaIdArr: [
          {required: true, message: "请选择所属机构", trigger: "change"},
        ],
        staffMobile: [
          {required: true, message: "请输入手机号码", trigger: "blur"},
          {validator: checkMobile, trigger: 'blur'}
        ],
       
      },
      uploadUrl: config.uploadUrl,
      sexState: sexList,
    };
  },
  mounted() {
    this.getCopanyInfo()
  },
  methods: {
    getCopanyInfo() {
      organizationTree.getOrganizationTree().then((res) => {
        // 递归遍历 生成 {value, label, children}
        let data = organizationCascader(res.data);
        this.copanyListInfo = data;
      });
    },
    cahngeMobile(){
      this.editForm.staffAccount = this.editForm.staffMobile
    },
    init(val) {
    
      this.searchForm.orgaId = val.orgaId;
      this.orgaId=val.orgaId;
      this.getList();
    },
    getList() {
      sysManage.selectStaff(this.searchForm).then((res) => {
        this.total = res.data.total;
        this.tableData = res.data.list;
      });
    },
    cascaderEditChange(val) {
      console.log(val);
      let valLen = val.length;
      this.editForm.orgaId = val[valLen - 1];
    },
    addFrom() {
      this.editForm={}
      this.showDialog = true
      this.dialogTitle='人员新增'
    },
    search() {
      console.log("searchForm", this.searchForm);
      this.searchForm.currPage = 1;
      this.searchForm.pageSize = 10;
      this.getList();
    },
    reset() {
      this.searchForm = {
        currPage: 1,
        pageSize: 10,
        orgaId:this.orgaId
      };
      this.getList();
    },
    tableExport() {
      // 导出
    },
    handleSelectionChange(val) {
      console.log(val);
    },
    colseDialog() {
      // 关闭编辑
      this.showDialog = false;
    },
    detailItem(item) {
      //详情
      console.log('item',item);
      this.editForm = item;
   
      
      if (item.orgaPathIds && item.orgaPathIds.match('-')) {
        const splitArray = item.orgaPathIds.split('-');
        const numericArray = splitArray[splitArray.length - 1] === '' ? splitArray.slice(0, -1) : splitArray;
        let orgaIdArr = numericArray.map(item => Number(item));
        this.editForm.orgaIdArr = orgaIdArr
        let orgaLevel  = this.$store.state.common.user.orgaLevel
        // this.editForm.orgaIdArr = orgaIdArr
        // if(orgaLevel == 0){
        //   this.editForm.orgaIdArr = orgaIdArr
        // }else if(orgaLevel == 1){
        //   let a = orgaIdArr.shift()
        //   this.editForm.orgaIdArr = orgaIdArr
        // }else {
        //   this.editForm.orgaIdArr = [orgaIdArr[2]]
        // }
      }else if(item.orgaPathIds){
        this.editForm.orgaIdArr = [Number(item.orgaPathIds)]
      }
      this.dialogTitle = "编辑管理人员";
      this.showDialog = true;
      // this.resetForm()
    },
    uploadChange(file) {
      console.log(file,'file');
      // common.fileUpload()
    },
    uploadSuccess(val) {
      console.log(val,'val');
      if (val.code == 200) {
        this.editForm.staffPic = val.message;
      }
    },
    deleteItem(item) {
      console.log([item.staffId]);
      console.log(item, 'item');
      this.$confirm(`此操作将永久删除该管理人员, 是否继续?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        //{staffId:[item.staffId]}
        //  return
        sysManage.delStaff({staffId:[item.staffId]}).then((res) => {
          this.$message({
            message: "删除成功",
            type: "success",
          });
          this.getList();
        });
      }).catch(() => {
        this.$message({
          type: "info",
          message: "已取消删除",
        });
      });
    },
    handleCurrentChange(pageIndex) {
      this.searchForm.currPage = pageIndex
      this.getList()
    },
    handleSizeChange(pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.currPage = 1
      this.getList()
    },
    editsubmit() {
      console.log(this.editForm)
      // this.editForm.projId=this.editForm.orgaId
      this.$refs.editStaffForm.validate((valid) => {
        if (valid) {
          if (this.editForm.staffId) {
            // 编辑
            sysManage.updateStaff(this.editForm).then((res) => {
              this.$message({
                message: "编辑成功",
                type: "success",
              });
              this.showDialog = false;
              this.getList();
            });
          } else {
            // 新增
            sysManage.newStaff(this.editForm).then((res) => {
              this.$message({
                message: "新增成功",
                type: "success",
              });
              this.showDialog = false;
              this.getList();
            });
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
.flex {
  display: flex;
  height: 100%;
  
  // justify-content: space-between;
}
.tree {
  width: 20%;
  height: 96%;
  
}
.form {
  width: 78%;
  margin-left: 2%;
}
.formItem {
  margin-bottom: 0px;
}
.btn {
  background-color: #008f4d;
  // color: #008f4d;
}

.detail {
  color: #008f4d;
}
.delete {
  margin-left: 10px;
}
</style>
